<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30"
      
    >
     <div style="text-align: center; margin: 0 auto;">
      <img style="width:300px;" :src="getFullPath('error_apologize.jpg')">
    </div>
      <!-- begin:: Content -->
      <h1
        class="font-size-sm-100 font-weight-boldest text-dark-75 mt-15 font-mitr"
        style="font-size: 60px; text-align:center;"
      >
        ไม่พบคูปอง
      </h1>
      <div>
        <p class="font-size-h2 font-weight-light font-mitr"
        style="text-align:center;"
        >
          ติดต่อร้านค้าที่ท่านได้รับคูปองมา
        </p>
      </div>
      <!-- end:: Content -->
      <!-- <a>กลับไปหน้าแรก</a> -->
    </div>
  </div>
</template>

<script>
import shapeFormat from '@/mixins/shapeFormat.js'
export default {
  mixins: [shapeFormat],
  name: "Error-1",
  mounted() {},
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "media/error/bg1.jpg";
    }
  }
};
</script>
